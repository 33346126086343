import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { interval, Subscription } from 'rxjs';
import { CryptoservService } from "src/app/services/cryposerv/cryptoserv.service";
import { EvaluacionService } from "src/app/services/evaluacion/evaluacion.service";
import { RutService } from "rut-chileno";

import Swal from 'sweetalert2';

@Component({
  selector: "app-evaluacion",
  templateUrl: "./evaluacion.component.html",
  styleUrls: ["./evaluacion.component.scss"],
})
export class EvaluacionComponent implements OnInit, OnDestroy {
  countdownTime: number; // Tiempo en segundos
  displayTime: string;
  private intervalId: any;
  showColon: boolean = true;
  horaInicio;
  horaTermino;
  nombre;
  rut;
  isCargando = false;

  preguntas = [
    {
      pregunta: "Con respecto a las temperaturas de conservación de la leche materna.: indique la alternativa correcta",
      alternativas: [
        "A temperatura ambiente (20 a 21 grados Celsius) dura un día.",
        "Una vez refrigerada no se puede calentar.",
        "A una Temperatura de 0 a 5 grados tiene una duración de 3 a 5 días. ",
        "La leche refrigerada se almacena como mínimo 3 días. "
      ],
      correcta: 3,
      respuesta: null
    },
    {
      pregunta: "La fórmula láctea alternativa  para el lactante de 3 a 6 meses es",
      alternativas: [
        "a)	200 cc leche 26% MG fortificada + maltosa dextrinada +aceite vegetal",
        "b)	220cc leche26% MG fortificada +cereal dextrinado",
        "c)	250 cc leche 26% MG fortificada +cereal dextrinado +aceite vegetal ",
        "d)	Ninguna de las anteriores"
      ],
      correcta: 0,
      respuesta: null
    },
    {
      pregunta: "El almacenamiento de la Leche Materna (extraída en la casa o en el recinto)",
      alternativas: [
        "Solo se recibe si tiene etiqueta con el nombre del lactante",
        "Solo se recibe si tiene etiqueta con el nombre del lactante y fecha",
        "Solo se recibe si tiene etiqueta con nombre del lactante,fecha y hora de extracción ",
        "Todas las anteriores "
      ],
      correcta: 2,
      respuesta: null
    },
    {
      pregunta: "De la leche materna, el proceso de calentar debe realizarse de la siguiente manera",
      alternativas: [
        "Se debe vaciar la leche a una olla luego calentar y después volver a colocar en la mamadera.",
        "Se debe colocar la mamadera en una olla con agua y hervirla a baño María, como mínimo al menos por 10min retirar y servir",
        "Se debe colocar en una olla a baño María, con agua ya hervida por 3 minutos aprox., luego sacar la mamadera ",
        "Ninguna alternativa es correcta."
      ],
      correcta: 2,
      respuesta: null
    },
    {
      pregunta: "La sección de Cocina de Leche es exclusivo para lo siguiente",
      alternativas: [
        "Preparar las fórmulas lácteas y papillas de los preescolares.",
        "Debe estar libre de contaminación para preparar solo fórmulas lácteas.",
        "Lugar en el cual se puede compartir para preparar leche de jardines.",
        "Las alternativas a y b son las correctas "
      ],
      correcta: 1,
      respuesta: null
    },
    {
      pregunta: "¿Cómo se deben guardar las mamaderas en la Cocina de leche?",
      alternativas: [
        "Una vez higienizadas dejarlas secar en un mesón sanitizado y taparlas con un paño limpio que las cubra por completo ",
        "Las mamaderas ya higienizadas se deben guardar completas (con chupete, porta chupetes y tapas) en el refrigerador",
        "Se deben mantener en la olla donde se hirvieron hasta que se vuelvan a utilizar y se deben sacar con tenazas",
        "Una vez higienizadas se deben colocar boca abajo a de costado en las primeras bandejas del refrigerador, y las argollas, chupetes deben quedar aparte en las rejillas del refrigerador "
      ],
      correcta: 1,
      respuesta: null
    },
    {
      pregunta: "El servicio de almuerzo para el personal del Jardín infantil",
      alternativas: [
        "Corresponde a la misma ración que se entrega a los niños",
        "Corresponde a 2 raciones que se entrega a los niños",
        "Les puedo preparar lo que ellas me pidan",
        "Corresponde a 1 ½ (1 y media) ración que se entrega a los niños "
      ],
      correcta: 3,
      respuesta: null
    }
  ]

  numPreguntas;
  rutUsuario;
  rutClean;

  constructor(
    private _router: Router,
    private cryptoServ: CryptoservService,
    private _evaluacionService: EvaluacionService,
    private rutService: RutService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    const validado = localStorage.getItem("sd332wcs2"); //Esto significa que llego desde el curso
    if(validado == "shwg72"){
      this._router.navigate(["/"]);
      return;
    }
    this.horaInicio = new Date();
    const tempRut = localStorage.getItem("rut");
    this.rutClean = this.cryptoServ.decrypted(tempRut);
    this.rutUsuario = this.rutService.rutFormat(this.rutClean);
    this.numPreguntas = this.preguntas.length;
    const usuario = localStorage.getItem("usuario");
    const aux = JSON.parse(usuario);
    this.nombre = this.cryptoServ.decrypted(aux.name);
  }

  ngOnDestroy(): void {
  }

  moveSelect(opcion, pregunta,pos){
    this.preguntas[pos].respuesta = opcion;
  }

  enviarRespuestaTiempoAcabado(){
    Swal.fire({
      title: "Advertencia",
      text: "El tiempo se acabado se enviaron las respuesta que tenia contestada hasta este momento",
      icon: "warning",
    });
    const horaTermino = new Date();
    const pregCorrectas = this.validarCantidadPregCorrectas();
   // this._examenService.enviarRespuesta(this.rut,this.nombre,this.horaInicio,horaTermino,this.preguntas, pregCorrectas);
    this._router.navigate(["/saludo"]);
  }

  validarCantidadPregCorrectas(){
    let cantPregCorrectas = 0;
    for (let index = 0; index < this.preguntas.length; index++) {
      const element = this.preguntas[index];
      if(element.respuesta == element.correcta){
        cantPregCorrectas++;
      }
    }
    return cantPregCorrectas;
  }

  seleccionRespuesta(posPregunta, posRespuesta){
    this.preguntas[posPregunta].respuesta = posRespuesta;

  }

  async enviarRespuestas(){
    this.isCargando = true;
    this._changeDetectorRef.markForCheck();
    let validador = true;
    for (let index = 0; index < this.preguntas.length; index++) {
      const element = this.preguntas[index];
      if(element.respuesta == null){
        validador = false;
        break;
      }
    }
    if(!validador){
      Swal.fire({
        title: "Advertencia",
        text: "Es necesario completar todo el examen para enviar las preguntas",
        icon: "warning",
        confirmButtonText: "volver"
      });
      this.isCargando =  false;
      this._changeDetectorRef.markForCheck();
      return;
    }
    Swal.fire({
      title: "Advertencia",
      text: "¿Estás seguro de enviar tus respuestas? Una vez confirmado, ya no podrás modificarlas",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cerrar"
    }).then(async (result) =>{
      if (result.isConfirmed) {
        const pregCorrectas = this.validarCantidadPregCorrectas();
        const horaTermino = new Date();
        const respuesta = await this._evaluacionService.enviarRespuesta(this.rutClean,this.nombre,this.horaInicio,horaTermino,this.preguntas, pregCorrectas);
        console.log(respuesta);
        Swal.fire({
          title: "Información",
          text: "Respuestas enviadas. Pronto te informarán sobre tus resultados"
        });
        localStorage.setItem("sd332wcs2", "shwg72"); // esto significa que rindio el curso
        this.isCargando =  false;
        this._router.navigate(["/curso"]);
      } else {
        this.isCargando =  false;
        this._changeDetectorRef.markForCheck();
      }
    });
    this.isCargando =  false;
    this._changeDetectorRef.markForCheck();
    return;
  }

}
