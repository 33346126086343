<div class="vh-100 d-flex flex-column justify-content-center fondoPrincipal">
  <div class="text-center w-full align-items-center text-center item-center">
    <div
      class="bg-gray-200 bg-opacity-75 max-w-90 mx-auto py-3 rounded-lg font-bold text-xl"
    >
      <h1 class="text-uppercase">¡Hola!</h1>
      <h2 class="text-uppercase">Verifica tu identidad</h2>
      <div class="w-full px-4 align-items-center text-center item-center">
        <div class="max-w-80 mx-auto">
          <!-- Aquí agregamos mx-auto para centrar horizontalmente -->
          <form
            class="text-center"
            (submit)="submit($event, rut.value)"
            ngNativeValidate
          >
            <input
              matInput
              (keypress)="validarInput($event)"
              class="form-control form-control-lg m-2"
              type="text"
              placeholder="Escribe aquí tu rut"
              aria-label=".form-control-lg"
              required
              #rut
            />
            <button
              type="submit"
              class="btn bg-orange-500 btn-lg w-100 m-2 text-xl font-bold"
              [disabled]="isLoading"
            >
              <span *ngIf="!isLoading">VERIFICAR</span>
              <span
                *ngIf="isLoading"
                class="inset-0 flex items-center justify-center"
              >
                <mat-spinner diameter="24" class="text-black"></mat-spinner>
              </span>
            </button>
          </form>
        </div>
        <!--
                <button class="btn btn-warning btn-lg w-100 m-2 fs-3 fw-bold" (click)="onHelp()">SOLICITAR AYUDA</button>
                -->
      </div>
    </div>
  </div>
</div>
