<div class="vh-100 d-flex flex-column justify-content-center fondoPrincipal">
  <div class="text-center w-full align-items-center text-center item-center">
    <div
      class="py-10 bg-orange-600 text-white text-2xl text-center font-bold w-full"
    >
      <h1>¡BIENVENID@!</h1>
      <p class="text-center">{{ user.name }}</p>
      <p class="text-center pb-5">
        Te invitamos a instalar nuestra aplicación.
      </p>
      <button
        class="btn btn-primary btn-lg btn-block font-weight-bold"
        (click)="installAndroid()"
      >
        INSTALAR APLICACIÓN
      </button>
    </div>
    <div class="py-2 bg-gray-300 bg-opacity-75 text-2xl text-center font-bold w-full py-2">
      <p class="text-center text-sm">
        Si no deseas instalar la aplicación, siempre podrás acceder a través de
        la web. Solo haz clic en "Entrar al curso".
      </p>
      <p class="text-center text-sm pb-2">
        Es importante tener en cuenta que de esta manera siempre necesitarás
        conexión a internet para continuar con el curso.
      </p>
      <button
        class="btn btn-primary btn-lg btn-block font-weight-bold"
        (click)="EntrarCurso()"
      >
        Entrar al curso
      </button>
    </div>
  </div>
</div>
