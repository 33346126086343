import { Injectable, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { data } from 'jquery';
//import { ConsoleReporter } from 'jasmine';
//import { Console } from 'console';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { nextTick } from 'process';
import { CryptoservService } from 'src/app/services/cryposerv/cryptoserv.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluacionService {
  infoTrack: string;
  pageTrack: number;
  moduleTrack: number;
  public validOnline: boolean;
  people: Observable<any>;
  constructor(
    private bdFirebase: AngularFirestore,
    private cryptoServ: CryptoservService
  ) { }

  async enviarRespuesta(
    rut: any,
    nombre: any,
    fechaInicioExamen,
    fechaTerminoExamen,
    respuesta,
    cantPreguntasCorrecta
  ) {
    try {
      const respStr = JSON.stringify(respuesta);
      console.log({
        rut: rut,
        fechaInicioExamen: fechaInicioExamen,
        fechaTerminoExamen: fechaTerminoExamen,
        fecUpdate: new Date(),
        nombre: nombre,
        respuesta: respStr,
        cantPreguntasCorrecta: cantPreguntasCorrecta
      });
      let nombreExamen = "examenSalaCuna";
      let rest = await this.bdFirebase.collection(nombreExamen).doc(rut).set(
        {
          fechaInicioExamen: fechaInicioExamen,
          fechaTerminoExamen: fechaTerminoExamen,
          fecUpdate: new Date(),
          nombre: nombre,
          respuesta: respStr,
          cantPreguntasCorrecta: cantPreguntasCorrecta
        },
        { merge: true }
      );
      return rest;
    } catch (error) {
      console.log("cai en el segundo error:" + error);
    }
  }

  async getAllExport(nombreColeccion) {
    let info = [];
    await this.bdFirebase
      .collection(nombreColeccion)
      .get()
      .toPromise()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          info.push(doc);
        });
      });
    return info;
  }
}
