import { Component, OnInit, ChangeDetectorRef, Renderer2, AfterViewInit } from "@angular/core";
import Swal from "sweetalert2";
import { PdfViewerModule } from "ng2-pdf-viewer";
//Inicio Importa para variable de avanzar pregunta
import { Router } from "@angular/router";
import { TrackingService } from "src/app/services/tracking/tracking.service";
import { CryptoservService } from "src/app/services/cryposerv/cryptoserv.service";
//Fin Importa para variable de avanzar pregunta
import { PDFDocumentProxy } from "pdfjs-dist";

@Component({
  selector: "app-modulo1",
  templateUrl: "./modulo1.component.html",
  styleUrls: ["./modulo1.component.scss"],
})
export class Modulo1Component implements OnInit, AfterViewInit {
  //Inicio Variable para avanzar de pagina
  numberModulo: number = 1;
  //Fin Variable para avanzar de pagina

  constructor(
    //Inicio declaracion de import para uno de avanzar pregunta
    private trackingService: TrackingService,
    private router: Router,
    private cryptoServ: CryptoservService,
    private renderer: Renderer2,
    private _changeDetectorRef: ChangeDetectorRef //Fin declaracion de import para uno de avanzar pregunta
  ) {}
  preguntas = [];
  respuesta = [];
  pathPdf = "../../../../assets/PDF/";

  newPdf = this.pathPdf + "modulo1_full.pdf";
  page = 1;
  totalPages: number = 99999;
  cantPreg = 4;

  //Pregunta numero 1
  //Pregunta 1
  tituloPregUno: string = "";
  descripcionPregUno: string =
    "En relación al Sistema de Gestión de la Calidad y Seguridad alimentaria del Programa de Alimentación Escolar podemos decir que:";
  opcPreguntaUno: string[] = [
    "Es un marco normativo que debemos cumplir.",
    "Especifica los requisitos para un Sistema de Gestión  de Calidad e inucoidad alimentaria del Programa de Alimentación Escolar.",
    "Se estructura en base a pilares como: la calidad, la sustentabilidad, la oportunidad y la creación del valor compartido.",
    "Todas las alternativas son correctas.",
  ];
  correctaPregUno: string = "Todas las alternativas son correctas.";
  
  //Pregunta 2
  tituloPregDos: string = "";
  descripcionPregDos: string =
    "Las enfermedades contraídas por el trabajador como consecuencia del trabajo serán consideradas como:";
  opcPreguntaDos: string[] = [
    "Enfermedad Profesional",
    "Accidente de trabajo",
    "Otras patologías",
    "Enfermedad común",
  ];
  correctaPregDos: string = "Enfermedad Profesional";

  //Pregunta 3
  tituloPregTres: string = "";
  descripcionPregTres: string =
    "La posibilidad de que un trabajador sufra un determinado daño derivado del trabajo";
  opcPreguntaTres: string[] = [
    "Enfermedad profesional",
    "Accidente de trabajo",
    "Riesgo laboral",
    "Ninguna de las anteriores",
  ];
  correctaPregTres: string = "Riesgo laboral";

  //Pregunta 4
  tituloPregCuatro: string = "";
  descripcionPregCuatro: string = "En caso de un accidente de trabajo";
  opcPreguntaCuatro: string[] = [
    "Debemos dar aviso a nuestro jefe de forma inmediata",
    "Debemos dar aviso a nuestro jefe en un plazo máximo de 48 horas",
    "Debemos seguir trabajando y evaluar la situación, para luego avisar a la jefatura",
    "Todas las alternativas son correctas",
  ];
  correctaPregCuatro: string =
    "Debemos dar aviso a nuestro jefe de forma inmediata";

  ngOnInit(): void {
    var paginaLink = +window.location.href.split("#")[1];
    var forPagina = "pagina" + paginaLink;
    console.log("paginaLink: " + paginaLink);
    var NumPag: number = paginaLink;
    console.log("num pagina: " + NumPag);
    this.validarAvancePagina(NumPag);
    //document.getElementById(forPagina).hidden = false;
  }

  ngAfterViewInit() {
    const pdfViewer = document.querySelector('pdf-viewer');

    if (pdfViewer) {
      const canvasWrapper = pdfViewer.querySelector('.canvasWrapper');
      const textLayer = pdfViewer.querySelector('.textLayer');

      if (canvasWrapper) {
        this.renderer.setStyle(canvasWrapper, 'overflow', 'auto');
      }

      if (textLayer) {
        this.renderer.setStyle(textLayer, 'position', 'relative');
      }

      // Asegúrate de que el visor del PDF se centra verticalmente
      this.renderer.setStyle(pdfViewer, 'display', 'flex');
      this.renderer.setStyle(pdfViewer, 'flex-direction', 'column');
      this.renderer.setStyle(pdfViewer, 'justify-content', 'center');
      this.renderer.setStyle(pdfViewer, 'align-items', 'center');
    }
  }

  validarAvancePagina(pagina) {
    switch (pagina) {
      case 2:
        this.page = 2;
        break;
      case 3:
        this.page = 4;
        break;
      case 4:
        this.page = 8;
        break;
      case 5:
        this.page = 12;
        break;
      case 6:
        this.page = 15;
        break;
      case 7:
        this.page = 18;
        break;
      case 8:
        this.page = 19;
        break;
      case 9:
        this.page = 21;
        break;
      case 10:
        this.page = 24;
        break;
      case 11:
        this.page = 25;
        break;
    }
  }

  validarSimpleQuestion(data: any, posicion: number) {
    if (data) {
      //localStorage.setItem("m1-p1", "true");
      const pregunta = this.numberModulo.toString() +"-" + posicion.toString();
      localStorage.setItem(pregunta, "true");
      this.page++;
      this.validSavePag();
      Swal.fire({
        icon: "success",
        title: "¡Super Bien!",
        text: "¡Tu respuesta esta correcta! 🥳",
        confirmButtonText: `¡Avanzar a la siguiente pregunta!`,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "¡No es correcta tu respuesta! 😔  Favor seguir intentando",
        confirmButtonText: `Volver a responder`,
      });
    }
  }

  validShowPregunta(pos) {
    if (this.page - this.totalPages == pos) {
      return true;
    }
    return false;
  }

  validRespondida(number){
    const pregunta = this.numberModulo.toString() +"-" + number.toString();
    const infoEncryp = localStorage.getItem(pregunta);
    if(infoEncryp == null || infoEncryp == undefined || infoEncryp == ""){
      return false;
    }
    return true;
  }

  async updateInfoBd(rut: string, modulo: number, pagina: number) {
    await this.trackingService.setAllTracking(rut, modulo, pagina);
  }

  //sath revisar
  validSavePag() {
    var temp = localStorage.getItem("modulo");
    var lastModule = this.cryptoServ.decrypted(temp);
    // var lastModule = localStorage.getItem('modulo');
    var temp = localStorage.getItem("pagina");
    var lastPagina = this.cryptoServ.decrypted(temp);
    // var lastPagina = localStorage.getItem('pagina');
    var temp = localStorage.getItem("rut");
    var rutUser = this.cryptoServ.decrypted(temp);
    // var rutUser = localStorage.getItem('rut');
    console.log(lastModule)
    console.log(lastPagina)
    console.log(rutUser)
    console.log(this.numberModulo)
    console.log('pagina:'+this.page)
    if (
      this.numberModulo == parseInt(lastModule) &&
      this.page >= parseInt(lastPagina)
    ) {
      var encrypt = this.cryptoServ.encrypted(this.page);
      localStorage.setItem("pagina", encrypt);
      // localStorage.setItem('pagina', JSON.stringify(this.page));
      console.log("Antes enviar")
      this.updateInfoBd(rutUser, this.numberModulo, this.page);
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
    this._changeDetectorRef.markForCheck();
  }

  // Método que se llama cuando se renderiza una página
  onPageRendered(event: any) {
    if (this.page === this.totalPages) {
      console.log("Última página del PDF mostrada");
      // Aquí puedes ejecutar cualquier lógica adicional que necesites
    }
  }

  // Método para ir a la página anterior
  prevPage() {
    if (this.page > 1) {
      this.page--;
    }
  }

  nextPage() {
    this.page++;
    //var encrypt = this.cryptoServ.encrypted(this.page);
    //localStorage.setItem("pagina", encrypt);
    //this.validSavePag();
  }

  isPaginaPdf() {
    if (this.page <= this.totalPages) {
      return true;
    }
    return false;
  }

  finModulo() {
    const tempModulo = localStorage.getItem("modulo");
    const lastModule = this.cryptoServ.decrypted(tempModulo);
    const tempRut = localStorage.getItem("rut");
    const rutUser = this.cryptoServ.decrypted(tempRut);
    if (parseInt(lastModule) < this.numberModulo + 1) {
      const newPage = 0;
      const numberModulo = this.numberModulo + 1;
      const encryptModulo = this.cryptoServ.encrypted(numberModulo);
      localStorage.setItem("modulo", encryptModulo);
      const encryptPagina = this.cryptoServ.encrypted(0);
      localStorage.setItem("pagina", encryptPagina);
      this.updateInfoBd(rutUser, numberModulo, newPage);
    }
    this.router.navigate(["/curso"]);
  }
}
