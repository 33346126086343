<div class="w-full h-full general">
  <div class="flex content-center items-center justify-center h-full p-1">
    <div
      class="flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-gray-200 border-0 card-encuesta"
    >
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0 card-content">
        <div class="grid grid-cols-2 px-3 gap-4">
          <div class="flex justify-end pr-10 items-center">
            <!-- Para alinear a la derecha -->
            <img
              src="assets/icons/icon-128x128.png"
              style="max-height: 130px; max-width: 150px"
            />
          </div>
          <div class="flex justify-start items-center">
            <!-- Para alinear a la izquierda -->
            <img
              src="assets/images/newAlicopsa.png"
              style="max-height: 100px; max-width: 150px"
            />
          </div>
        </div>

        <div class="text-blueGray-800 text-center mb-3 text-2xl font-bold">
          EVALUACIÓN SALA CUNA
        </div>

        <div class="text-justify text-lg font-bold p-2">
          Favor seleccionar alternativa correcta
        </div>
        <div class="text-justify text-md px-2">
          La evaluación es de {{ numPreguntas }} preguntas de alternativas
        </div>
        <div class="w-full p-3 text-justify">
          <div
            *ngFor="let question of preguntas; let i = index"
            class="flex flex-col mb-4"
          >
            <div>
              <label class="text-lg font-bold"
                >{{ i + 1 }} - {{ question.pregunta }}</label
              >
            </div>
            <div class="w-full">
              <div
                *ngFor="let option of question.alternativas; let j = index"
                class="flex list-group-item text-md"
                (click)="seleccionRespuesta(i, j)"
              >
                <input
                  type="radio"
                  [checked]="j == question.respuesta"
                  class="pr-2"
                />
                <label class="pl-2" for="option{{ i }}"> {{ option }}</label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-full font-bold text-blueGray-600 text-lg py-3 text-center"
        >
          Por favor, revisa tus preguntas
        </div>
        <div class="w-full">
          <button
          class="btn bg-orange-500 btn-lg w-full m-2 text-xl font-bold"
            type="button"
            (click)="enviarRespuestas()"
            [disabled]="isCargando"
          >
            <span *ngIf="!isCargando">Enviar respuestas</span>
            <span
              *ngIf="isCargando"
              class="inset-0 flex items-center justify-center"
            >
              <mat-spinner diameter="24" class="text-black"></mat-spinner>
            </span>
          </button>
          <div class="px-3 text-center font-bold text-2xl " *ngIf="isCargando">
            Subiendo respuestas
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
